var render = function render(){var _vm=this,_c=_vm._self._c;return _c('draggable',{staticClass:"steps",attrs:{"disabled":true},on:{"end":_vm.onEnd},model:{value:(_vm.startNodes),callback:function ($$v) {_vm.startNodes=$$v},expression:"startNodes"}},[_vm._l((_vm.startNodes),function(item,index){return _c('div',{staticClass:"step",class:[
      _vm.getIsBlock(item),
      _vm.getIsHeadOrTailClass(index, item),
      _vm.getIsReverseStepClass(index),
    ]},[(item.name)?[_c('el-tooltip',{attrs:{"open-delay":1000,"effect":"dark","content":item.name,"placement":"top"}},[_c('el-tag',{attrs:{"color":_vm.getColor(item)}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),(_vm.showArrow(item, index))?_c('i',{class:_vm.getArrowClass(index, item)}):_vm._e()]:_vm._e(),(Array.isArray(item) && item.block)?[_c('div',{staticClass:"step-block-inner"},[_vm._l((item),function(item2,index2){return [_c('el-tooltip',{key:index2,attrs:{"open-delay":1000,"effect":"dark","content":item2.name,"placement":"top"}},[_c('el-tag',{attrs:{"color":_vm.getColor(item2)}},[_vm._v(" "+_vm._s(item2.name)+" ")])],1)]})],2),_c('i',{class:_vm.getArrowClass(index)})]:_vm._e()],2)}),_c('div',{staticClass:"arr"},_vm._l((_vm.arrData),function(item,index){return _c('div',{key:'giao' + index,staticStyle:{"margin-top":"30px"}},[_c('h2',{staticStyle:{"margin-bottom":"10px"},style:({
          color: item[0].origin.preActionResult === 'fail' ? 'red' : 'green',
        }),on:{"click":function($event){return _vm.print(item)}}},[_vm._v(" "+_vm._s(item[0].origin.preActionResult)+" ")]),_c('steps',{attrs:{"data":item}})],1)}),0)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }