<template>
  <draggable :disabled="true" class="steps" v-model="startNodes" @end="onEnd">
    <div
      :class="[
        getIsBlock(item),
        getIsHeadOrTailClass(index, item),
        getIsReverseStepClass(index),
      ]"
      class="step"
      v-for="(item, index) in startNodes"
    >
      <template v-if="item.name">
        <el-tooltip
          :open-delay="1000"
          effect="dark"
          :content="item.name"
          placement="top"
        >
          <el-tag :color="getColor(item)">
            <!-- <span v-if="item.origin.groupId">
              ({{ item.origin.groupId }})
            </span> -->
            {{ item.name }}
          </el-tag>
        </el-tooltip>

        <i
          v-if="showArrow(item, index)"
          :class="getArrowClass(index, item)"
        ></i>
      </template>
      <template v-if="Array.isArray(item) && item.block">
        <div class="step-block-inner">
          <template v-for="(item2, index2) in item">
            <el-tooltip
              :key="index2"
              :open-delay="1000"
              effect="dark"
              :content="item2.name"
              placement="top"
            >
              <el-tag :color="getColor(item2)">
                <!-- <span v-if="item2.origin.groupId">
                  ({{ item2.origin.groupId }})
                </span> -->
                {{ item2.name }}
              </el-tag>
            </el-tooltip>
          </template>
        </div>
        <i :class="getArrowClass(index)"></i>
      </template>
    </div>
    <div class="arr">
      <div
        style="margin-top: 30px"
        v-for="(item, index) in arrData"
        :key="'giao' + index"
      >
        <h2
          :style="{
            color: item[0].origin.preActionResult === 'fail' ? 'red' : 'green',
          }"
          @click="print(item)"
          style="margin-bottom: 10px"
        >
          {{ item[0].origin.preActionResult }}
        </h2>
        <steps :data="item"></steps>
      </div>
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: 'steps',
  components: { draggable },
  props: ['data'],
  data() {
    return {
      startNodes: null,
      arrData: [],
      dialogVisible: false,
      dialogTitle: '',
      dialogTypes: ['changeLevel', 'addNode'],
      dialogType: null,
      willChangeLevel: null,
      dialogItem: null,
      willChangeLevelMin: 0,
      willChangeLevelMax: 10000,
      newNodeName: null,
      newNodeLevel: null,
      newNodeType: null,
      blockData: null,
    }
  },
  mounted() {
    this.startNodes = this.data
    // 为了区分样式
    this.arrData = this.startNodes.filter(item => {
      return Array.isArray(item) && !item.block
    })
    this.startNodes = this.startNodes.filter(item => {
      return !Array.isArray(item) || (Array.isArray(item) && item.block)
    })
    // this.arrData = this.startNodes.filter(item => {
    //   return Array.isArray(item)
    // })
    console.log(this.startNodes, 'startNodes')
    console.log(this.arrData, 'arrData')
    this.arrData = this.arrData
      .map(arr => {
        // 确保每行都有5个元素，不足则补充空对象
        while (arr.length % 5 !== 0) {
          arr.push({ name: '' })
        }
        return arr
      })
      .map(arr => {
        // 对每行的元素进行反转
        const resultArr = [...arr] // 创建数组副本进行操作
        for (let i = 0; i < resultArr.length; i += 5) {
          const row = Math.floor(i / 5) + 1
          if (row % 2 === 0) {
            const start = i
            const end = i + 5
            const subArray = resultArr.slice(start, end)
            const reverseSubArray = subArray.reverse()
            // console.log(resultArr.slice(start, end), 'subArray')
            // console.log(reverseSubArray, 'reverseSubArray')
            resultArr.splice(start, 5, ...reverseSubArray)
          }
        }
        console.log(resultArr, 'giao')
        return resultArr
      })

    console.log(this.arrData, 'arr')
  },
  watch: {
    data(val) {
      // console.log('监听到数据变化,触发初始化 steps')
      this.init()
    },
  },
  methods: {
    init() {
      this.startNodes = this.data
      // 为了区分样式
      this.arrData = this.startNodes.filter(item => {
        return Array.isArray(item) && !item.block
      })
      this.startNodes = this.startNodes.filter(item => {
        return !Array.isArray(item) || (Array.isArray(item) && item.block)
      })
      // this.arrData = this.startNodes.filter(item => {
      //   return Array.isArray(item)
      // })
      // console.log(this.startNodes, 'startNodes')
      // console.log(this.arrData, 'arrData')
      this.arrData = this.arrData
        .map(arr => {
          // 确保每行都有5个元素，不足则补充空对象
          while (arr.length % 5 !== 0) {
            arr.push({ name: '' })
          }
          return arr
        })
        .map(arr => {
          // 对每行的元素进行反转
          const resultArr = [...arr] // 创建数组副本进行操作
          for (let i = 0; i < resultArr.length; i += 5) {
            const row = Math.floor(i / 5) + 1
            if (row % 2 === 0) {
              const start = i
              const end = i + 5
              const subArray = resultArr.slice(start, end)
              const reverseSubArray = subArray.reverse()
              // console.log(resultArr.slice(start, end), 'subArray')
              // console.log(reverseSubArray, 'reverseSubArray')
              resultArr.splice(start, 5, ...reverseSubArray)
            }
          }
          // console.log(resultArr, 'giao')
          return resultArr
        })

      // console.log(this.arrData, 'arr')
      // console.log(this.arrData[0], 'arr[0]')
      console.log('!!!!!!!!!!!!!!!!!!!!')
      console.log(this.startNodes, 'startNodes init')
      if (this.arrData[0]) {
        let a = this.arrData[0].map(item => {
          let res = {
            name: item.name,
            origin: item,
          }
          return res
        })
        console.log(a, 'arrData init')
      }
      console.log('!!!!!!!!!!!!!!!!!!!!')
    },
    showArrow(item, index) {
      // console.log(item, 'item')
      if (index !== this.startNodes.length - 1) {
        // console.log('不是最后一位')
        let nextNode = this.startNodes[index + 1]
        if (index % 5 == 4) {
          nextNode = this.startNodes[index + 5]
        }
        if (!Array.isArray(nextNode) && !nextNode.name) {
          // console.log('下一位不是数组且没有name')
          // console.log(nextNode, 'nextNode')
          return false
        }
      }
      return (
        (!item.origin.nextActionTemplates &&
          index !== this.startNodes.length - 1) ||
        (item.origin.nextActionTemplates &&
          item.origin.nextActionTemplates.length > 0)
      )
    },
    getSan() {
      this.startNodes.filter(item => {
        return !Array.isArray(item)
      })
    },
    changeLevel(item, index, arr) {
      this.dialogTitle = `修改${item.name}层级`
      this.dialogVisible = true
      this.dialogType = this.dialogTypes[0]
      this.dialogItem = item
      // 考虑最低限制
      this.willChangeLevel = this.dialogItem.origin.groupId
        ? this.dialogItem.origin.groupId
        : 0
      console.log(this.willChangeLevel, 'giao')

      let prevNode = arr[index - 1]
      let nextNode = arr[index + 1]
      let prevNodeGI = prevNode.origin.groupId
      let nextNodeGI = nextNode.origin.groupId
      let index1 = index
      let index2 = index
      while (index1 > 0 && !prevNodeGI) {
        index1--
        prevNode = arr[index1]
        prevNodeGI = prevNode.origin.groupId
      }
      while (index2 < arr.length - 1 && !nextNodeGI) {
        index2++
        nextNode = arr[index2]
        nextNodeGI = nextNode.origin.groupId
      }

      // 如果前面没有gi，但是更前面有，以更前面的为参考
      // 如果前面没有gi，更前面也没有
      // 如果前面有gi，则用gi
      this.willChangeLevelMin = prevNodeGI
        ? prevNodeGI
        : this.dialogItem.origin.groupId

      // 如果后面没有gi，但是更后面有,用同层级
      // 如果后面没有gi，更后面也没有
      // 如果后面有gi，则用gi
      this.willChangeLevelMax = nextNodeGI
        ? nextNodeGI
        : this.dialogItem.origin.groupId
      console.log(index, arr, arr[index])
      console.log(prevNode, 'prevNode')
      console.log(nextNode, 'nextNode')
      console.log(prevNodeGI, 'prevNodeGI')
      console.log(nextNodeGI, 'nextNodeGI')
      console.log(this.willChangeLevelMin, 'willChangeLevelMin')
      console.log(this.willChangeLevelMax, 'willChangeLevelMax')
    },
    handleWillChangeLevelChange(e) {
      // let group = this.dialogItem.origin.groupId
      // if (e > group + 1) {
      //   console.log('>', group + 1)
      //   this.willChangeLevel = group
      // }
      // if (e < group - 1) {
      //   this.willChangeLevel = group - 1
      // }
    },
    addNode(item, type) {
      if (type == 'after') {
        this.dialogTitle = '向后添加节点'
      } else {
        this.dialogTitle = '向前添加节点'
      }
      this.dialogVisible = true
      this.dialogType = this.dialogTypes[1]
      this.dialogItem = item
      this.newNodeLevel = this.dialogItem.origin.groupId
      this.willChangeLevelMin = this.willChangeLevel - 1
      this.willChangeLevelMax = this.willChangeLevel + 1
      this.newNodeType = type
    },
    getColor(item) {
      let num = Number(item.origin.serialId)
      if (num === 0) return
      const numberColorMap = {}

      function generateColor(index) {
        // HSL: Hue ranges from 0 to 360, Saturation and Lightness are adjusted for lighter colors
        const hue = (index * 137.508) % 360 // 137.508 is the golden angle in degrees
        return `hsl(${hue}, 70%, 80%)` // 70% saturation, 80% lightness for lighter colors
      }

      function getColorForNumber(number) {
        if (!numberColorMap[number]) {
          // Assign a unique color using generateColor function
          numberColorMap[number] = generateColor(number)
        }
        return numberColorMap[number]
      }
      return getColorForNumber(num)
    },
    getIsBlock(item) {
      return item.block ? 'step-block' : ''
    },
    getArrowClass(index, item) {
      // 获取当前元素所在的行数，行数从 1 开始
      const row = Math.floor(index / 5) + 1

      // 判断当前元素是否为该行的最后一个元素
      if (row % 2 === 1 && (index + 1) % 5 === 0) {
        return 'el-icon-bottom'
      }

      if (row % 2 === 0 && index % 5 === 0) {
        return 'el-icon-bottom'
      }
      // 根据行数的奇偶性返回不同的箭头方向
      if (row % 2 == 0) {
        return 'el-icon-back'
      } else {
        return 'el-icon-right'
      }
    },
    getIsReverseStepClass(index) {
      // 获取当前元素所在的行数，行数从 1 开始
      const row = Math.floor(index / 5) + 1

      // 偶数行反转
      if (row % 2 === 0) {
        return 'step-reverse'
      }
    },
    getIsHeadOrTailClass(index, item) {
      // console.log(item, 'item')
      // 获取当前元素所在的行数，行数从 1 开始
      const row = Math.floor(index / 5) + 1
      // 奇数行 末尾
      if (row % 2 === 1 && (index + 1) % 5 === 0) {
        return 'isHeadOrTail'
      }
      // 偶数行 头部
      if (row % 2 === 0 && index % 5 === 0) {
        return 'isHeadOrTail'
      }
    },

    print(val) {
      console.log(val, 'val')
    },
    onEnd(event) {
      // 这里可以添加拖拽结束后的逻辑，例如保存新的顺序
      console.log('拖拽结束:', this.startNodes)
      let arr = []
      let nor = []
      this.startNodes.forEach(item => {
        if (Array.isArray(item)) {
          arr.push(item)
        } else {
          nor.push(item)
        }
      })
      this.startNodes = nor.concat(arr).filter(item => {
        return item !== undefined
      })
      console.log(this.startNodes, 'startNodes')
      //   this.startNodes = this.startNodes.reduce((total, cur) => {
      //     return total
      //   }, [])
    },
  },
}
</script>
<style lang="scss" scoped>
.steps {
  display: flex;
  flex-wrap: wrap;
  .arr {
    width: 100%;
    /* flex: 1; */
  }

  .step {
    width: 100%;
    /* .el-tooltip {
      width: 100%;
    }
    > span {
      flex: 1;
      ::v-deep.el-popover__reference-wrapper {
        width: 100%;
      }
    }
    > span /deep/ .el-popover__reference-wrapper {
      display: inline-block;
      width: 100%;
      > * {
        width: 100%;
      }
    } */
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    margin-bottom: 70px;
    position: relative;
    padding: 0 10px;
    box-sizing: border-box;

    max-width: 20%;
    flex-basis: 20%;
    flex-shrink: 1;
    flex-grow: 1;
    .el-tag {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    i {
      transform: scaleX(2);
      /* width: 20px;
      transform: scaleX(1.6); */
      position: relative;
      right: -10px;
    }
  }
  .step-block {
    justify-content: center;
    .step-block-inner {
      /* background-color: pink; */
      width: calc(100% - 30px);
      display: flex;
      flex-direction: column;
      gap: 5px;
      .el-tag {
        width: 100%;
      }
    }
    /* display: block; */
  }
  .step-reverse {
    flex-direction: row-reverse;
    /* padding-left: 0; */

    > i {
      position: relative;
      left: -10px;
    }
  }
  .isHeadOrTail {
    /* background: #bfa; */
    flex-direction: column;
    /* align-items: flex-start; */
    justify-content: center;
    /* margin-bottom: 0px; */
    .el-tag {
      flex: none;
      width: 100%;
      /* width: calc(100% - 30px);
      height: 35px; */
    }
    i {
      left: auto;
      right: auto;

      position: absolute;
      /* bottom: 0%; */
      bottom: 0%;
      transform: scaleY(3.5) translateY(70%);
      /* margin: 5px auto;
      position: relative;
      left: -15px; */
    }
  }
}
</style>
